//import css
@import '../../../App.scss';

.aug-leadership-team {
    margin-top: 140px;
    @media (max-width: 767.98px) {
        margin-top: 100px;
    }
    .gx-80{
        @media (max-width: 1199.98px) {
            --bs-gutter-x: 1.5rem;
        }
    }
    .aug-leadership-team__title{
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        font-weight: 700;
        margin-bottom: 80px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 40px;
        }
    }
    .aug-leadership-team-item {
        margin-bottom: 60px;
        @media (max-width: 767.98px) {
            margin-bottom: 32px;
        }
        .aug-leadership-team-name {
            font-size: 30px;
            white-space: nowrap;
            font-weight: 700;
            line-height: 38px;
            font-family: $font-family-secondary;
            letter-spacing: -2px;
            margin-top: 24px;
            @media (max-width: 991.98px) {
                font-size: 20px;
                line-height: 30px;
                margin-top: 16px;
            }
        }
        .aug-leadership-team-designation {
            opacity: 70%;
            font-size: 18px;
            line-height: 27px;
            margin-top: 8px;
            @media (min-width: 992px) {
                white-space: nowrap;
            }
            @media (max-width: 991.98px) {
                font-size: 16px;
                line-height: 24px;
                min-height: 54px;
            }
            @media (max-width: 575.98px) {
                min-height: auto;
            }
        }
        a{
            margin-top: 24px;
            display: inline-block;
            @media (max-width: 767.98px) {
                margin-top: 16px;
            }
        }
        .aug-leadership-team-img{
            border-bottom: 2px solid $active-color;
            border-radius: 8px;
            img{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                border-radius: 10px;
                max-height: 440px;
                width: 100%;
                object-fit: cover;
                &:hover{
                    -webkit-filter: none;
                    filter: none;
                }
            }
        }
    }
}
