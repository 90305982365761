//import css
@import '../../../Assets/scss/style.scss';

.aug-faq{
    padding: 140px 0;
    @media (max-width: 767.98px) {
        padding: 100px 0;
    }
    .aug-faq-title{
        font-size: 40px;
        line-height: 52px;
        font-family: $font-family-secondary;
        font-weight: $font-weight-bold;
        margin-bottom: 56px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 28px;
        }
    }
    .aug-accordion{
        .accordion-item{
            background-color: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid rgba(211, 219, 210, 0.5);
        }
        .accordion-button, .accordion-body{
            color: $white-color;
        }
        .accordion-button{
            font-size: $font-size24;
            line-height: 48px;
            background-color: transparent;
            padding: 24px 0;
            @media (max-width: 767.98px) {
                font-size: 18px;
                line-height: 28px;
                padding: 16px 0;
            }
            &:focus{
                border-color:transparent;
                box-shadow:none
            }
            &:focus-visible{
                outline:0;
            }
            &:not(.collapsed){
                background-color: transparent;
                box-shadow:none;
                padding-bottom: 0;
            }
            &::after{
                background-image: url('../../../Assets/images/icons/dropdown.svg');
                transform: rotate(-0deg);
            }
            &:not(.collapsed)::after{
                transform: rotate(180deg);
            }
        }
        .accordion-body{
           padding: 16px 0 24px 0;
           font-size: 18px;
           line-height: 27px;
           opacity: 70%;
           @media (max-width: 767.98px) {
                font-size: 16px;
                line-height: 24px;
                padding: 12px 0 16px 0;
            }
        }
        [dir="rtl"]{
            .accordion-button{
                &::after{
                   margin-right: auto;
                   margin-left: initial;
                }
            }
        }
    }
}