//btn
.btn{
    padding: 12px 24px;
    font-family: $font-family;
    font-size: $font-size16;
    border-radius: 2px;
    color: $primary-color;
    line-height: $line-height-normal;
    box-shadow: none;
    transition: 0.3s;
    border:1px solid;
    &.btn-outline-white{
        background-color: transparent;
        border-color:$white-color;
        color: $white-color;
        border-radius: 100px;
        font-weight: 500;
        &:hover{
            color: $primary-color;
            background-color: $white-color;
            border-color: $white-color;
            svg{
                path{
                    fill:$primary-color;
                    width: 30px;
                }
            } 
        }
    }
    &.btn-primary{
        color:$primary-color;
        background-color: $active-color;
        border-color: $active-color;
        font-weight: 500;
        border-radius: 100px;
        &:hover{
            color: $primary-color;
            background-color: $white-color;
            border-color: $white-color;
        }
    }
    &.btn-outline-primary{
        color: $primary-color;
        background-color: transparent;
        border-color: $primary-color;
        &:hover{
            background-color: #E6EFF1;
            border-color: #E6EFF1;
        }
    }
}
