//import css
@import '../../../Assets/scss/style.scss';

.aug-clinical-note{
    &.aug-efficient-documentation{
        padding-top: 0;
        .aug-efficient-documentation__item{
            .aug-efficient-documentation__video{
                video{
                    border: 2px solid $active-color;
                    border-radius: 26px;
                }
            }
        }
    }
}