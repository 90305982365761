//import css
@import '../../../Assets/scss/style.scss';

.aug-ou-solution{
    background-color: $secondary-color;
    padding: 80px 0;
    border-radius: 40px;
    @media (max-width: 767.98px) {
        padding: 60px 0;
    }
    .aug-ou-solution__title{
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        font-weight: 700;
        font-family: $font-family-secondary;
        margin-bottom: 60px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 40px;
        }
    }
    .aug-ou-solution__item{
        background-color: $primary-color;
        border-radius: 20px;
        padding: 60px 40px;
        @media (max-width: 991.98px) {
            margin-bottom: 60px;
        }
        @media (max-width: 767.98px) {
            padding: 24px 16px;
            border-radius: 16px;
            margin-bottom: 40px;
        }
        .aug-ou-solution__item-title{
            margin-left: 12px;
            font-size: $font-size32;
            line-height: 38px;
            letter-spacing: -2px;
            font-weight: 700;
            font-family: $font-family-secondary;
            @media (max-width: 767.98px) {
                font-size: $font-size20;
                line-height: 30px;
                margin-left: 8px;
            }
        }
        p{
            opacity: 70%;
            font-size: 18px;
            line-height: 27px;
            min-height: 108px;
            @media (max-width: 199.98px) {
                min-height: 162px;
            }
            @media (max-width: 1024.98px) {
                min-height: 136px;
            }
            @media (max-width: 991.98px) {
                min-height: auto;
            }
            @media (max-width: 767.98px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .aug-ou-solution__item-list{
            font-size: $font-size18;
            line-height: 27px;
            margin-bottom: 40px;
            @media (max-width: 767.98px) {
                font-size: $font-size16;
                line-height: 24px;
            }
        }
        .aug-ou-solution__item-list-title{
            font-weight: 600;
            font-family: $font-family-secondary;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -2px;
            margin-bottom: 16px;
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
            }
        }
    }
}

[dir="rtl"]{
    &.aug-ou-solution{
        .aug-ou-solution__item-title{
            margin-right: 12px;
            margin-left: initial;
        }
    }
}