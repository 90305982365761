//import css
@import '../../../App.scss';

.aug-our-offices{
    padding-top: 100px;
    padding-bottom: 140px;
    @media (max-width: 767.98px) {
        padding-bottom: 100px;
        padding-top: 60px;
    }
    .aug-our-offices__title{
        font-size: 40px;
        line-height: 52px;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        margin-bottom: 80px;
        text-align: center;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            margin-bottom: 40px;
            font-size: 26px;
            line-height: 34px;
        }
    }
    .aug-our-offices__place{
        margin-top: 80px;
        @media (max-width: 767.98px) {
            margin-top: 60px;
        }
        .aug-our-offices__icone{
            margin-bottom: 24px;
        }
        .aug-our-offices__name{
            font-weight: $font-weight-bold;
            font-size: $font-size32;
            line-height: 38px;
            font-family: $font-family-secondary;
            margin-bottom: 16px;
            letter-spacing: -2px;
            @media (max-width: 767.98px) {
                font-size: $font-size20;
                line-height: 30px;
                margin-bottom: 12px;
            }
        }
        p{
            opacity: 70%;
            margin-bottom: 16px;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
                line-height: 21px;
            }
        }
        a{
            color: $active-color;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
                line-height: 21px;
            }
        }
    }
    
}


