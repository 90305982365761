//import css
@import '../../../Assets/scss/style.scss';

.aug-our-team {
    padding-top: 100px;

    .slick-slide {
        padding-right: 40px;

        @media (max-width: 1199.98px) {
            padding-right: 0px;
        }
    }

    .aug-career__title {
        @media (max-width: 767.98px) {
            margin-bottom: 80px;
        }
    }

    .aug-our-team__items {
        background-color: $secondary-color;
        border-radius: 40px;
        padding: 40px;
        height: 432px;

        @media (max-width: 991.98px) {
            height: auto;
            padding: 24px 24px 32px;
        }

        @media (max-width: 767.98px) {
            padding: 16px 16px 40px;
        }

        img {
            border-radius: 20px;
            height: 240px;

            @media (max-width: 991.98px) {
                height: 180px;
            }

            @media (max-width: 767.98px) {
                height: 140px;
            }
        }

        .aug-our-team__items-content {
            margin-left: 40px;

            @media (max-width: 991.98px) {
                margin-left: 24px;
            }

            @media (max-width: 767.98px) {
                margin-left: 0;
                margin-top: 16px;
                padding-left: 8px;
            }

            .aug-our-team__items-content-height {
                height: 278px;

                @media (max-width: 991.98px) {
                    height: auto;
                }
            }

            p {
                max-height: 278px;
                font-size: 24px;
                line-height: 32px;
                border-left: 2px solid $active-color;
                padding-left: 12px;

                @media (max-width: 991.98px) {
                    max-height: 100%;
                }

                @media (max-width: 767.98px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .aug-our-team__items-profile {
                font-size: $font-size24;
                line-height: 34px;
                font-weight: $font-weight-semibold;
                font-family: $font-family-secondary;
                letter-spacing: -2px;
                margin-top: 40px;

                span {
                    font-weight: $font-weight-normal;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $font-family;
                    letter-spacing: 0;
                }

                @media (max-width: 767.98px) {
                    font-size: 18px;
                    line-height: 24px;

                    span {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.aug-our-team-slider {

    .slick-prev,
    .slick-next {
        width: 48px;
        height: 48px;
        background-color: $secondary-color;
        border-radius: 50%;
        top: -107px;
        z-index: 9;

        @media (max-width: 767.98px) {
            width: 32px;
            height: 32px;
            top: -55px;
        }

        &::before {
            display: none;
        }
    }

    .slick-prev {
        left: initial;
        right: 54px;
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        top: -68px;
        bottom: initial;
        text-align: left;

        li {
            margin: 0 1px;

            button {
                &::before {
                    font-size: 10px;
                    opacity: 1;
                    color: $secondary-color;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                        color: $active-color;
                        background-color: #2CE100;
                        height: 8px;
                        width: 24px;
                        border-radius: 100px;
                        content: "";
                        top: 5px;
                    }
                }
            }
        }
    }
}

[dir="rtl"] {
    &.aug-our-team {
        .slick-dots {
            text-align: right;
        }

        .slick-prev {
            right: initial;
            left: 0;
        }

        .slick-next {
            left: 54px;
            right: initial;
        }
    }

    .aug-our-team__items {
        .aug-our-team__items-content {
            margin-right: 40px;
            margin-left: initial;

            p {
                border-right: 2px solid $active-color;
                border-left: 0;
                padding-right: 12px;
                padding-left: initial;
            }
        }
    }
}