//import css
@import '../../../Assets/scss/style.scss';

.aug-spectra-banner{
    padding: 120px 0 100px;
    @media (max-width: 991.98px) {
        padding-top: 80px;
    }
    .aug-spectra-banner-content{
        h6{
            color: $active-color;
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
        }
        h1{
            letter-spacing: -2px;
            font-weight: 700;
            font-size: 44px;
            line-height: 58px;
            font-family: $font-family-secondary;
        }
        p{
            margin-bottom: 40px;
            font-size: 18px;
            line-height: 27px;
            opacity: 70%;
        }
    }
    @media (max-width: 767.98px) {
        padding: 60px 0 80px;
        .aug-spectra-banner-content{
            h6{
                font-size: 16px;
                line-height: 24px;
            }
            h1{
                font-size: 32px;
                line-height: 38px;
            }
            p{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .aug-spectra-video{
        @media (max-width: 991.98px) {
            margin-top: 80px;
        }
        @media (max-width: 767.98px) {
            margin-top: 60px;
        }
        video{
            border: 0;
        }
    }
}