//import css
@import '../../Assets/scss/style.scss';

.aug-thankyou-banner{
    padding: 80px 0;
    @media (max-width: 991.98px) {
        padding: 120px 0 140px;
    }
    @media (max-width: 767.98px) {
        padding: 80px 0 100px;
    }
    .aug-thankyou-banner__heading{
        font-size: 44px;
        line-height: 58px;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    p{
        font-size: 18px;
        line-height: 27px;
        opacity: 70%;
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .btn{
        margin-top: 40px;
    }
    @media (max-width: 991.98px) {
        .aug-thankyou-banner__img{
            display: none;
        }
    }
}