//import css
@import '../../../Assets/scss/style.scss';

.aug-efficient-documentation{
    padding-top: 140px;
    @media (max-width: 767.98px) {
        padding-top: 100px;
    }
    .aug-efficient-documentation__title{
        letter-spacing: -2px;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        text-align: center;
        font-family: $font-family-secondary;
        margin-bottom: 80px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 40px;
        }
    }
    .aug-efficient-documentation__item{
        margin-bottom: 140px;
        @media (max-width: 767.98px) {
            margin-bottom: 60px;
        }
        .aug-efficient-documentation__content{
            h6{
                color: $active-color;
                font-size: 18px;
                line-height: 27px;
                font-weight: 400;
            }
            h3{
                letter-spacing: -2px;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
                font-family: $font-family-secondary; 
            }
            p{
                font-size: 18px;
                line-height: 27px;
                opacity: 70%;
            }
        }
        @media (max-width: 991.98px) {
            .aug-efficient-documentation__content{
                margin-bottom: 40px;
            }
        }
        @media (max-width: 767.98px) {
            .aug-efficient-documentation__content{
                margin-bottom: 24px;
                h6{
                    font-size: 16px;
                    line-height: 24px;
                }
                h3{
                    font-size: 20px;
                    line-height: 30px;
                }
                p{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}