//import css
@import '../../../App.scss';

.aug-banner{
    background-color: $primary-color;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 26px;
    justify-content: space-between;
    overflow-x: hidden;
    @media (max-width: 991.98px) {
        padding: 80px 0 80px;
    }
    @media (max-width: 767.98px) {
        padding: 60px 0;
    }
    .aug-banner-img{
        img{
            height: 382px;
            width: 286px;
            object-fit: cover;
            @media (max-width: 991.98px) {
                height: 200px;
                width: 150px;
            }
            @media (max-width: 767.98px) {
                height: 160px;
                width: 120px;
            }
        }
        &:first-child{
            margin-left: -160px;
            @media (max-width: 991.98px) {
                margin-left: -50px;
            }
            @media (max-width: 767.98px) {
                margin-left: -25px;
            }
        }
        &:last-child{
            margin-right: -160px;
            @media (max-width: 991.98px) {
                margin-right: -50px;
            }
            @media (max-width: 767.98px) {
                margin-right: -25px;
            }
        }
    }
    .aug-banner-content{
        h1{
            font-weight: $font-weight-bold;
            font-size: 42px;
            line-height: 55px;
            letter-spacing: -2px;
            font-family: $font-family-secondary;
            @media (max-width: 767.98px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
        p{
            font-size: 18px;
            line-height: 27px;
            opacity: 70%;
            margin-bottom: 40px;
            @media (max-width: 767.98px) {
              font-size: 16px;
              line-height: 24px;
            }
        }
    }
}

[dir="rtl"]{
    &.aug-banner{
        .aug-banner-img{
            &:first-child{
                margin-left: initial;
                margin-right: -78px;
                @media (max-width: 991.98px) {
                    margin-left: initial;
                    margin-right: -50px;
                }
                @media (max-width: 767.98px) {
                    margin-left: initial;
                    margin-right: -25px;
                }
            }
            &:last-child{
                margin-right: initial;
                margin-left: -78px;
                @media (max-width: 991.98px) {
                    margin-right: initial;
                    margin-left: -50px;
                }
                @media (max-width: 767.98px) {
                    margin-right: initial;
                    margin-left: -25px;
                }
            }
        }
        .aug-banner-content{
            svg{
                margin-top: -10px;
                @media (max-width: 991.98px) {
                    margin-top: -5px;
                }
            }
        }
    }
}
