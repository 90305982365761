//import css
@import '../../../App.scss';

.aug-help-you{
    padding-top: 80px;
    @media (max-width: 767.98px) {
        padding-top: 60px;
    }
    .aug-help-you__title{
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 60px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 40px;
        }
    }
    .aug-card{
        background-color: $secondary-color;
        border-radius: 20px;
        margin-bottom: 40px;
        img{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            height: 220px;
            object-fit: cover;
            @media (max-width: 991.98px) {
                height: 185px;
            }
        }
        .aug-card-body {
            padding: 24px 24px 32px;
            height: 222px;
            @media (max-width: 767.98px) {
                height: auto;
            }
            .aug-card-header{
                font-weight: $font-weight-semibold;
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 0;
                font-family: $font-family-secondary;
                letter-spacing: -2px;
                @media (max-width: 767.98px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            p, span{
                margin-top: 12px;
                opacity: 70%;
                @media (max-width: 767.98px) {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            a{
                color: $active-color;
                @media (max-width: 767.98px) {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            .btn{
                margin-top: 24px;
                color: $white-color;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    } 
}