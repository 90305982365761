@font-face {
    font-family: 'Stage Grotesk';
    src: url('../../fonts/stage-grotesk/OTF/StageGrotesk-Regular.otf') format('opentype'),
         url('../../fonts/stage-grotesk/TTF/StageGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Stage Grotesk';
    src: url('../../fonts/stage-grotesk/OTF/StageGrotesk-Medium.otf') format('opentype'),
        url('../../fonts/stage-grotesk/TTF/StageGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Stage Grotesk';
    src: url('../../fonts/stage-grotesk/OTF/StageGrotesk-Bold.otf') format('opentype'),
        url('../../fonts/stage-grotesk/TTF/StageGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Brooklyn Heavy font
@font-face {
    font-family: 'Brooklyn-Heavy';
    src: url('../../fonts/Brooklyn/OTF/Brooklyn-Normal.otf') format('opentype'),
         url('../../fonts/Brooklyn/TTF/Brooklyn-Normal.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Brooklyn-Heavy';
    src: url('../../fonts/Brooklyn/OTF/Brooklyn-SemiBold.otf') format('opentype'),
         url('../../fonts/Brooklyn/TTF/Brooklyn-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Brooklyn-Heavy';
    src: url('../../fonts/Brooklyn/OTF/Brooklyn-Bold.otf') format('opentype'),
         url('../../fonts/Brooklyn/TTF/Brooklyn-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

