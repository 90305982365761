//import css
@import '../../../Assets/scss/style.scss';

.aug-our-ethos{
    margin-bottom: 140px;
    @media (max-width: 767.98px) {
        margin-bottom: 100px;
    }
    .aug-our-ethos-item{
        border-radius: 12px;
        background-color: $secondary-color;
        padding: 24px 32px;
        margin-bottom: 24px;
        position: relative;
        @media (max-width: 767.98px) {
            padding: 24px 12px;
            margin-bottom: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .aug-our-ethos-item-title{
            font-family: $font-family-secondary;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -2px;
            @media (max-width: 767.98px) {
                font-size: 18px;
                line-height: 24px;
            }
        }
        p{
            font-size: 14px;
            line-height: 21px;
            opacity: 70%;
            margin-top: 6px;
            @media (max-width: 767.98px) {
                margin-top: 12px;
            }
        }
        &::before{
            content: '';
            position: absolute;
            height: 1px;
            width: 16%;
            background-color: $active-color;
            left: -16.5%;
            top: 57%;
            @media (max-width: 991.98px) {
                display: none;
            }
        }
        &::after{
            position: absolute;
            content: '';
            transform: rotate(90deg);
            top: 56%;
            left: -1%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $active-color;
            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
    .aug-our-ethos-centricity{
        background-color: $secondary-color;
        padding: 67px 12px;
        border-radius: 12px;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -2px;
        font-weight: 700;
        font-family: $font-family-secondary;
        text-align: center;
        position: relative;
        @media (max-width: 991.98px) {
            margin-bottom: 48px;
        }
        &::before{
            content: '';
            position: absolute;
            height: 1px;
            width: 25%;
            background-color: $active-color;
            right: -25%;
            top: 50%;
            @media (max-width: 1199.98px) {
                top: 57%;
            }
            @media (max-width: 991.98px) {
                display: none;
            }
        }
        @media (max-width: 991.98px) {
            &::after{
                position: absolute;
                content: ' ';
                content: url(../../../Assets/images/icons/long-arrow-down.svg);
                top: 100%;
                left: 50%;
            }
        }
        

        @media (max-width: 767.98px) {
            padding: 40px 46px;
            font-size: 20px;
            line-height: 30px;
        }
    }
    .aug-our-ethos-line{
        position: relative;
        width: 1px;
        height: 79%;
        top: 12%;
        left: 50%;
        background-color: $active-color;
        @media (min-width: 1319.98px) {
            top: 12.6%;
            height: 78%;
        }
        @media (max-width: 1199.98px) {
            top: 12.5%;
            height: 78%;
        }
        @media (max-width: 991.98px) {
            display: none;
        }
    }
}

[dir="rtl"]{
    &.aug-our-ethos{
        .aug-our-ethos-line{
            right: 50%;
            height: 77%;
            top: 14%;
            @media (min-width: 1319.98px) {
                top: 12.6%;
                height: 78%;
            }
            @media (max-width: 1199.98px) {
                height: 78%;
                top: 13.5%;
            }
        }
        .aug-our-ethos-item{
            &::before{
                right: -16.5%;
                top: 57%;
            }
            &::after{
                top: 56%;
                right: -1%;
                transform: rotate(-90deg);
            } 
        }
        .aug-our-ethos-centricity{
            &::before{
                left: -25%;
                right: initial;
                @media (min-width: 1319.98px) {
                    top: 43%;
                }
                @media (max-width: 1199.98px) {
                    top: 51%;
                }
                @media (max-width: 991.98px) {
                    left: 49.8%;
                    right: initial;
                }
            }
        }
    }
}