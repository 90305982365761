//import css
@import '../../../Assets/scss/style.scss';

.aug-page-card{
    padding: 80px 0 100px;
    @media (max-width: 767.98px) {
        padding: 40px 0 76px;
    }
    .aug-page-card__item{
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 24px;
        }
    }
    .aug-page-card__box{
        background-color: $secondary-color;
        padding: 40px 24px;
        @media (max-width: 767.98px) {
            padding: 32px 24px;
        }
        border-radius: 20px;
        border: 1px solid rgba(80, 80, 80, 0.5);
        height:100%;
        transition: 0.3s;
        &:hover{
            h3{
                color: $active-color;
            }
        }
        h3{
            font-size: $font-size24;
            font-weight: $font-weight-semibold;
            line-height: 34px;
            font-family: $font-family-secondary;
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
            }
        }
        p{
            opacity: 70%;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
                line-height: 21px;
            }
        }
    }
}