.container-fluid{
    padding-right: 80px;
    padding-left: 80px;
    @media (max-width: 991.98px) { 
        padding-right: 40px;
        padding-left: 40px;
    }
    @media (max-width: 767.98px) { 
        padding-right: 20px;
        padding-left: 20px;
    }
}
.dropdown-toggle{
    &::after{
        display: none;
    }
}
a:focus-visible {
    outline: 0;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}