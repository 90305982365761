//import css
@import '../../../Assets/scss/style.scss';

.aug-collage-gallery{
    padding-top: 80px;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767.98px) {
        padding-top: 40px;
    }
    .aug-collage-gallery__items{
        padding: 0 20px;
        img{
            max-height: 534px;
            min-height: 534px;
            object-fit: cover;
            width: 100%; 
            border-radius: 12px;
            &:focus-visible {
                outline: 0;
            }
            @media (max-width: 1320.98px) {
                max-height: 484px;
                min-height: 484px;
            }
            @media (max-width: 1140.98px) {
                max-height: 434px;
                min-height: 434px;
            }
            @media (max-width: 991.98px) {
                max-height: 400px;
                min-height: 400px;
            }
            @media (max-width: 575.98px) {
                max-height: 334px;
                min-height: 334px;
            }
        }
        &:focus-visible {
            outline: 0;
        }
    }
}