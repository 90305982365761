//import css
@import '../../../Assets/scss/style.scss';

.aug-footer{
    color: $white-color;
    .aug-footer-wrap{
        background-color: $secondary-color;
        font-weight: $font-weight-normal;
        font-size: $font-size16;
        .aug-footer-wrap-content{
            padding-top: 80px;
            padding-bottom: 60px;
            @media (max-width: 991.98px) { 
                padding-bottom: 124px;
            }
            @media (max-width: 767.98px) {
                padding-bottom: 36px;
            }
        }
        a{
            color: $white-color;
            line-height: $line-height-40;
            display: inline-block;
            font-size: $font-size16;
        }
    }
    .aug-footer-logo{
        a{
            margin-bottom: 40px;
            @media (max-width: 767.98px) {
                margin-bottom: 24px;
            }
        }
        .aug-brand-logo{
            ul{
                li{
                    margin-right: 16px;
                    &:last-child{
                        margin-right: 0px;
                        @media (max-width: 575.98px) {
                            margin-top: 8px;
                        }
                    }
                    img{
                        opacity: 70%;
                    }
                }
            }
            @media (max-width: 991.98px) {
                margin-bottom: 60px;
            }
        }
    }
    .aug-list-heading{
        font-size: $font-size14;
        font-weight: $font-weight-normal;
        line-height: 21px;
        text-transform: uppercase;
        opacity: 80%;
        font-family: $font-family-secondary;
    }
    .aug-footer-copyright{
        background-color: $primary-color;
        padding: 20px 0;
        @media (max-width: 991.98px) {
            padding: 24px 0;
        }
        .aug-social-copyright-region{
            opacity: 70%;
            .aug-copyright{
                font-size: 12px;
                line-height: 18px;
                font-weight: $font-weight-normal;
                @media (max-width: 991.98px) {
                    margin-bottom: 18px;
                }
            }
            .aug-region, .aug-legel{
                font-weight: $font-weight-normal;
                line-height: 18px;
                font-size: 12px;
            }
            .aug-social{
                li{
                    margin-left: 24px;
                    @media (max-width: 767.98px) {
                        margin-left: 18px;
                    }
                    &:first-child{
                        margin-left: 0px;
                    }
                    &:nth-child(4){
                        svg{
                            width: 18px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

[dir="rtl"] {
    &.aug-footer{
        .aug-footer-wrap{
            .aug-footer-wrap-content{
                .list-unstyled{
                    padding-right: 0;
                    padding-left: initial;
                }
                .aug-brand-logo{
                    ul{
                        li{
                            margin-left: 16px;
                            margin-right: initial;
                            &:last-child{
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }
        }
        .aug-footer-copyright{
            .aug-social-copyright-region{
                opacity: 80%;
                .aug-social{
                    li{
                        margin-left: initial;
                        margin-right: 24px;
                        @media (max-width: 767.98px) {
                            margin-right: 18px;
                            margin-left: initial;
                        }
                        &:first-child{
                            margin-right: 0px;
                            margin-left: initial;
                        }
                    }
                }
            }
        }
    }
}