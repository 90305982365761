//import css
@import '../../../Assets/scss/style.scss';

.aug-why-spectra{
    &.aug-augnito-difference{
        margin-bottom: 80px;
        padding-top: 0;
        @media (max-width: 767.98px) {
            margin-bottom: 60px;
        }
        .aug-augnito-difference__item{
            margin-bottom: 60px;
            @media (max-width: 767.98px) {
                margin-bottom: 40px;
            }
        }
    }
}


