//import css
@import '../../Assets/scss/style.scss';

.aug-error-page{
    .aug-error-oops{
        padding: 120px 0 140px;
        @media (max-width: 767.98px) {
            padding: 60px 0 100px;
            svg{
                height: 48px;
                width: 48px;
            }
        }
        .aug-error-page__heading{
            font-weight: $font-weight-bold;
            font-size: 44px;
            line-height: 58px;
            font-family: $font-family-secondary;
            margin-bottom: 16px;
            @media (max-width: 767.98px) {
                font-size: $font-size32;
                line-height: 38px;
            }
        }
        .aug-arror-code{
            font-size: $font-size18;
            line-height: 27px;
            opacity: 70%;
            @media (max-width: 767.98px) {
                font-size: $font-size16;
                line-height: 24px;
            }
        }
        .aug-helpfull-link{
            padding-top: 60px;
            @media (max-width: 767.98px) {
                padding-top: 40px;
            }
            p{
                font-size: 18px;
                line-height: 27px;
                @media (max-width: 767.98px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            ul{
                margin-top: 16px;
                display: flex;
                @media (max-width: 767.98px) {
                    display: block;
                    margin-top: 24px;
                }
                li{
                    a{
                        color: $active-color;
                        margin-right: 48px;
                        @media (max-width: 767.98px) {
                            margin-bottom: 16px;
                            margin-right: 0px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
    
    
    
}