//import css
@import '../../../App.scss';

.aug-universal-accessibility{
    .aug-universal-accessibility-wrap{
        padding: 140px 0 100px 0;
        @media (max-width: 991.98px) {
            padding: 140px 0 80px 0;
        }
        @media (max-width: 767.98px) {
            padding: 100px 0 60px 0;
        }
    }
    h2{
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
        }
    }
    p{
        opacity: 70%;
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 991.98px) {
            margin-bottom: 40px;
        }
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
        }
    }
    .aug-universal-accessibility__img{
        ul{
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            @media (max-width: 991.98px) {
                gap: 16px;
                justify-content: flex-start;
            }
            @media (max-width: 991.98px) {
                margin-top: 40px;
            }
            @media (max-width: 767.98px) {
                margin-top: 32px;
                flex-wrap: wrap;
                justify-content: start;
            }
            li{
                background-color: $secondary-color;
                border-radius: 8px;
                width: 110px;
                height: 136px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                svg{
                    display: block;
                }
                span{
                    display: block;
                    font-size: $font-size16;
                    opacity: 40%;
                    margin-top: 12px;
                }
                @media (max-width: 767.98px) {
                    width: 97px;
                    height: 120px;
                    svg{
                        width: 55px;
                        height: 46px;
                    }
                    span{
                        font-size: $font-size14;
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
[dir="rtl"]{
    &.aug-universal-accessibility{
        .aug-universal-accessibility__img{
            ul{
                padding-right: 0;
                li{
                    @media (max-width: 991.98px) {
                    }
                    @media (max-width: 767.98px) {
                    }
                }
            }
        }
    } 
}