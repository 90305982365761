//import css
@import '../../Assets/scss/style.scss';

.aug-legal-page{
    margin: 120px 0;
    font-size: 18px;
    line-height: 27px;
    @media (max-width: 991.98px) {
        font-size: 16px;
        line-height: 24px;
    }
    .aug-legal-page-header{
        margin-bottom: 60px;
        p{
            margin-bottom: 40px;
        }
    }
    .aug-legal-page-title{
        line-height: 58px;
        font-weight: 700;
        font-size: 44px;
        letter-spacing: -2px;
        font-family: $font-family-secondary;
        margin-bottom: 60px;
        @media (max-width: 991.98px) {
            margin-bottom: 24px;
        }
    }
    .aug-legal-page-subtitle{

        line-height: 38px;
        font-size: $font-size24;
        font-weight: 600;
        font-family: $font-family-secondary;
        letter-spacing: -2px;
        margin-bottom: 16px;
        color: $white-color;
    }
    p{
        font-size: 18px;
        line-height: 27px;
        opacity: 70%;
        @media (max-width: 991.98px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .form-select{
        background-color: $secondary-color;
        background-image: url(../../Assets/images/icons/dropdown.svg);
        border: 0;
        color: $white-color;
        opacity: 70%;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        &:focus{
            box-shadow: none;
        }
    }
    ul{
        padding-left: 34px;
        li{
            margin-bottom: 4px;
            display: flex;
            font-size: 18px;
            line-height: 27px;
            strong{
                margin-right: 4px;
            }
            @media (max-width: 991.98px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .list-style-disc{
        padding-left: 16px;
        li{
            position: relative;
            &::before{
                content: "•";
                color: $white-color;
                position: absolute;
                left: -16px;
            }
        }
    }
    .table {
        --bs-table-striped-color: rgba(255, 255, 255, 0.7);
        --bs-table-striped-bg: #202020;
        width: 100%;
        margin-bottom: 0;
        color: $white-color;
        border-color: #505050;
        font-size: 16px;
        line-height: 24px;
        td{
            padding: 16px 24px;
        }
    }
    .table-dark {
        th{
            padding: 12px 24px;
            background-color: #505050;
            font-weight: 400;
            white-space: nowrap;
        }
    }
    a{
        color: $active-color;
    }
}