//import css
@import '../../../Assets/scss/style.scss';

.aug-contact-sales{
    padding-top: 120px;
    @media (max-width: 767.98px) {
        padding: 80px 0 60px;
    }
    .aug-contact-sales-contant{
        @media (max-width: 991.98px) {
            margin-bottom: -90px;
        }
        @media (max-width: 767.98px) {
            margin-bottom: 0px;
        }
    }
    h6{
        font-size: $font-size18;
        line-height: 27px;
        font-weight: $font-weight-normal;
        color: $active-color;
        @media (max-width: 767.98px) {
            font-size: $font-size16;
            line-height: 24px;
        }
    }
    h1{
        font-size: 44px;
        line-height: 58px;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        margin-bottom: 16px;
        text-wrap: nowrap;
        @media (max-width: 991.98px) {
            text-wrap: wrap;
        }
        @media (max-width: 767.98px) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    p{
        opacity: 70%;
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 60px;
        }
    }
    .aug-contact-sales__img{
        img{
            width: 265px;
            object-fit: contain;
            margin-bottom: -25px;
        }
        @media (max-width: 991.98px) {
            img{
                height: 274px;
                width: 205px;
                margin-bottom: -120px;
            }
        }
        @media (max-width: 767.98px) {
            display: none;
        }
    }
}