//import css
@import '../../../Assets/scss/style.scss';

.aug-schedule-demo{
    background-color: $secondary-color;
    border-radius: 20px;
    padding: 60px;
    @media (max-width: 991.98px) {
        padding: 60px 32px;
    }
    @media (max-width: 767.98px) {
        border-radius: 16px;
        padding: 40px 12px;
    }

    .aug-schedule-demo__title{
        font-size: $font-size32;
        line-height: 38px;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        margin-bottom: 48px;
        @media (max-width: 767.98px) {
            font-size: $font-size20;
            line-height: 30px;
            margin-bottom: 24px;
        }
    }
    .aug-schedule-demo__list{
        li{
            font-size: 18px;
            line-height: 27px;
            opacity: 70%;
            margin-bottom: 16px;
            svg{
                margin-top: 4px;
            }
        }
    }
    .aug-schedule-demo__form{
        position: relative;
        @media (max-width: 991.98px) {
            padding-top: 80px;
        }
        @media (max-width: 767.98px) {
            padding-top: 40px;
        }
        .aug-schedule-demo__error{
            color: $danger-color;
            position: absolute;
            top: 42px;
            @media (max-width: 767.98px) {
                top: 65px;
                font-size: $font-size14;
            }
        }
        .form-control{
            background-color: #505050;
            border-radius: 4px;
            border-color: #505050;
            padding: 12px;
            color: rgba(255, 255, 255, 0.7);
            font-size: 16px;
            &:focus{
                border-color: $active-color;
                box-shadow: none;
            }
            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
            &::-ms-input-placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
        }
        .btn{
            margin-top: 40px;
        }
        .form-select{
            background-image: url(../../../Assets/images/icons/dropdown.svg);
        }
        .form-check-input{
            background-color: transparent;
            border-color:#D3DBD2;
            border-radius: 2px;
            height: 18px;
            width: 18px;
            &:focus{
                box-shadow: none;
            }
        }
        .form-check-label{
            opacity: 70%;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
            }
        }
    }
}

[dir="rtl"]{
    .aug-schedule-demo{
        .aug-schedule-demo__list{
            padding-right: 0;
            list-style: none;
        }
        .form-select{
            background-position: left 0.75rem center;
        }
        .form-check {
            padding-right: 1.5em;
            .form-check-input {
                float: right;
                margin-right: -1.5em;
            }
        }
    }
}