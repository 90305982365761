//import css
@import '../../../App.scss';

.aug-perfec-solution{
    background-color: $secondary-color;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    h2{
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 44px;
        line-height: 58px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    p{
        opacity: 70%;
        margin-bottom: 40px;
        a{
            color: $active-color;
        }
    }
    .aug-perfec-solution-wrap{
        padding: 60px 0 60px 0;
        border-bottom: 1px solid rgba(211, 219, 210, 0.5);
        @media (max-width: 991.98px) {
            padding: 60px 0 60px 0;
        }
        @media (max-width: 767.98px) {
            padding: 40px 0 40px 0;
        }
    }
    
    .aug-perfec-solution__img{
        text-align: right;
        img{
            width: 344px;
            height: 344px;
        }
        @media (max-width: 767.98px) {
            width: 280px;
            height: 280px;
        }
        @media (max-width: 991.98px) {
            margin-top: 80px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            img{
                margin: auto;
            }
        }
        @media (max-width: 991.98px) {
            margin-top: 40px;
        }
    }
}
[dir="rtl"]{
    &.aug-perfec-solution{
        .aug-perfec-solution__img{
            text-align: left;
        }
    }
}