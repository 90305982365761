.aug-sticky-header {
    @media (max-width: 767.98px) { 
        &.sticky-top {
           top: -1px;
        }
    }
    &.product-sticky {
        position: relative !important;
        @media (max-width: 767.98px) { 
            &.sticky-top {
               top: 0px;
            }
        }
    }
}