//import css
@import '../../../Assets/scss/style.scss';

.aug-our-partner{
    padding: 140px 0 140px;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767.98px) {
        padding: 100px 0;
    }
    h2{
        font-family: $font-family-secondary;
        font-weight: $font-weight-semibold;
        font-size: 24px;
        line-height:34px;
        text-align: center;
        margin-bottom: 80px;
        letter-spacing: -2px;
        span{
            border-bottom: 2px solid $active-color;
        }
        @media (max-width: 767.98px) {
            margin-bottom: 40px;
            font-size: 18px;
            line-height:28px;
        }
        @media (min-width: 992px) {
            br{
                display: none;
            }
        }
        @media (min-width: 768px) {
            br:first-of-type{
                display: none;
            }
        }
    }
    .slick-track{
        display: flex;
        align-items: center;
    }
    .slick-slide{
       padding: 0 32px;
        .aug-our-partner-items{
            text-align: center;
            width: 100%;
            max-height: 60px;
            img{
                object-fit: fill;
                max-height: 50px;
            }
            @media (max-width: 991.98px) {
                max-height: 50px;
                img{
                    max-height: 40px;
                }
            }
           
        }
    }
}
