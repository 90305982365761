//import css
@import '../../../Assets/scss/style.scss';

.aug-career__title{
    font-size: 40px;
    line-height: 52px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    letter-spacing: -2px;
    margin-bottom: 80px;
    @media (max-width: 767.98px) {
        margin-bottom: 40px;
        font-size: 26px;
        line-height: 36px;
    }
}
.aug-augnito-difference{
    padding-top: 140px;
    @media (max-width: 767.98px) {
        padding-top: 100px;
    }
    .aug-augnito-difference__item{
        margin-bottom: 80px;
        @media (max-width: 767.98px) {
            margin-bottom: 40px;
        }
        h3{
            font-weight: $font-weight-semibold;
            font-size: $font-size24;
            line-height: 34px;
            font-family: $font-family-secondary;
            letter-spacing: -2px;
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
            }
        }
        p{
            opacity: 70%;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
                line-height: 21px;
            }
        }
        .aug-augnito-difference__icone{
            position: relative;
            margin-bottom: 12px;
            &::after{
                background-color: #2c6b2f;
                filter: blur(25px);
                content: "";
                border-radius: 100px;
                height: 48px;
                width: 48px;
                top: 0;
                left: 0;
                position: absolute;
            }
        }
    }
}

[dir="rtl"]{
    &.aug-augnito-difference{
        .aug-augnito-difference__icone{
            &::after{
                right: 0;
                left: initial;
            }
        }
    }
}