//import css
@import '../../../App.scss';

.aug-navbar{
    &.navbar{
        transition: all .5s ease-in-out;
        background-color: $primary-color;
        border-bottom: 1px solid rgba(80, 80, 80, 0.5);
        padding-top: 24px;
        padding-bottom: 24px;
        @media (max-width: 991.98px) { 
            padding-top: 16px;
            padding-bottom: 16px;
            .navbar-collapse{
                border-top:1px solid rgba(80, 80, 80, 0.5);
                margin-top: 24px;
            }
        }
        .navbar-nav{
            @media (max-width: 991.98px) { 
                height: 89.5vh;
                align-items: baseline;
                margin-top: 32px;
                overflow-y: auto;
            }
            @media (max-width: 575.98px) { 
                height: 85vh;
                margin-top: 24px;
            }
            .aug-nav-item-border{
                position: relative;
                display: inline-block;
                &::after{
                    position: absolute;
                    content: '';
                    height: 18px;
                    width: 1px;
                    right: 0;
                    top: -15px;
                    background-color: $white-color;
                }
                @media (max-width: 991.98px) { 
                    display: none;
                }
            }
            .nav-item{
                margin-left: 40px;
                &:first-child{
                    margin-left: 0px;
                }
                @media (max-width: 991.98px) { 
                    margin-left: 0px;
                }
                .nav-link{
                    color: $white-color;
                    font-weight: $font-weight-normal;
                    font-size: $font-size16;
                    padding: 0;
                    @media (max-width: 991.98px) { 
                        padding: 0.5rem 0;
                        display: flex;
                        align-items: center;
                    }
                    &.show{
                        @media (max-width: 991.98px) { 
                            svg{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                button{
                    &.nav-link{
                        background-color: transparent;
                        border: 0;
                        width: 100%;
                        text-align: left;
                    }
                }
                @media (max-width: 991.98px) { 
                    padding: 16px 0;
                    border-bottom:1px solid rgba(80, 80, 80, 0.5);
                    width: 100%;
                    svg{
                        &.mx-1{
                            margin-left: auto !important;
                        }
                    }
                    &:nth-child(5){
                        &.position-relative{
                            display: none;
                        }
                    }
                    &:nth-child(4), &:nth-child(6), &:nth-child(7){
                        border: 0;
                    }
                    &:nth-child(7){
                        padding: 12px 0;
                    }
                    .btn-outline-white{
                        margin-top: 4vh;
                    }
                }
                .btn{
                    @media (max-width: 991.98px) { 
                        width: 100%;
                    }
                }
                .dropdown-menu{
                    min-width:180px;
                    border-color: #505050;
                    border-radius: 12px;
                    background-color: $secondary-color;
                    padding: 12px 0;
                    top: calc(100% + 36px);
                    .nav-link{
                        color: $white-color;
                        padding: 12px 24px;
                        &:hover{
                            background-color: transparent;
                            color: $active-color;
                        }
                    }
                }
            }

        }
        .navbar-toggler{
            padding: 0px;
            &:focus{
                box-shadow: none;
            }
            &[aria-expanded="false"]{
                .aug-icon-open{
                    display: block;
                }
                .aug-icon-close{
                    display: none;
                }
            }
            &[aria-expanded="true"]{
                .aug-icon-open{
                    display: none;
                }
                .aug-icon-close{
                    display: block;
                }
            }
        } 
    }

    &[dir="rtl"] {
        .nav-item{
            margin-right: 40px;
            margin-left: initial !important;
                &:first-child{
                    margin-right: 0px;
                }
                @media (max-width: 991.98px) { 
                    margin-right: 0px;
            }
            [dir="rtl"]{
                &.dropdown-menu[data-bs-popper]{
                    right: 0;
                }
            }
        }
        &.navbar{
            .navbar-nav{
                svg{
                    @media (max-width: 991.98px) {
                        &.mx-1 {
                            margin-left: initial !important;
                            margin-right: auto !important;
                        }
                    }
                }
            }
        }
       
    }

    .navbar-nav{
        .dropdown{
            .dropdown-toggle.show{
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    top: calc(100% + 34px);
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 2px;
                    border-radius: 2px;
                    background-color: $active-color;
                    @media (max-width: 991.98px) { 
                        top: 100%;
                    }
                }
            }
            .dropdown-menu{
                li{
                    .nav-link{
                        &.active{
                            color: $active-color;
                        }
                    }
                }
            }
        }
    }
}