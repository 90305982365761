//import css
@import '../../../Assets/scss/style.scss';

.aug-career-keka{
    padding: 60px 0 140px;
    @media (max-width: 767.98px) {
        padding: 60px 0 100px;
    }
    p{
        // margin-bottom: 60px;
        opacity: 70%;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            // margin-bottom: 40px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 24px;
        }
    }
    a{
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    // #kekaCareerRMS{
    //     border: 0;
    //     height: 604px;
    //     width: 100%;
    //     padding-top: 16px;
    //     background-color: $white-color;
    // }
}
