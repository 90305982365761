//import css
@import '../../../Assets/scss/style.scss';

.aug-customer-say{
    // padding: 140x 0;
    .aug-customer-say-title{
        font-weight: $font-weight-bold;
        font-size: 40px;
        line-height: 52px;
        font-family: $font-family-secondary;
        margin-bottom: 88px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
        }
    }
    .aug-customer-slider{
        .slick-prev, .slick-next{
            width: 48px;
            height: 48px;
            background-color: $secondary-color;
            border-radius: 50%;
            top: -115px;
            z-index: 9;
            @media (max-width: 767.98px) {
                width: 32px;
                height: 32px;
                top: -55px;
            }
            &::before{
                display: none;
            }
        }
        .slick-prev {
            left: initial;
            right: 54px;
        }
        .slick-next {
            right: 0;
        }
        .slick-slide{
            padding: 0 40px;
            @media (max-width: 1199.98px) {
                padding: 0 15px;
            }
            @media (max-width: 991.98px) {
                padding: 0px;
            }
        }
        .slick-dots{
            top: -68px;
            bottom: initial;
            text-align: left;
            li{
                margin: 0 1px;
                button{
                    &::before{
                        font-size: 10px;
                        opacity: 1;
                        color: $secondary-color;
                    }
                }
                &.slick-active{
                    button{
                        &::before{
                            opacity: 1;
                            color: $active-color;
                            background-color: #2CE100;
                            height: 8px;
                            width: 24px;
                            border-radius: 100px;
                            content: "";
                            top: 5px;
                        }
                    }
                }
            }
        }
        .aug-customer-items{
            background-color: $secondary-color;
            border-radius: 40px;
            min-height: 500px;
            padding: 32px 24px;
            position: relative;
            @media (max-width: 991.98px) {
                min-height: 440px;
            }
            @media (max-width: 767.98px) {
                padding: 32px 16px;
            }
            .aug-customer-items__personal{
                padding-right: 108px;
            }
            img{
                height: 130px;
                width: 130px;
                object-fit: cover;
                position: absolute;
                top: 0;
                right: 0;
            }
            .aug-customer-items__text{
                min-height: 238px;
                max-height: 238px;
                overflow-y: auto;
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 8px;
                @media (max-width: 991.98px) {
                    min-height: 200px;
                    max-height: 200px;
                }
                @media (max-width: 767.98px) {
                    font-size: 18px;
                    line-height: 28px;
                }
                .aug-customer-items-read{
                    color: $active-color;
                    cursor: pointer;
                }
                /* width */
                &::-webkit-scrollbar {
                    width: 2px;
                }  
                /* Track */
                &::-webkit-scrollbar-track {
                    background: rgba(255, 255, 255, 0.7);
                } 
                /* Handle */
                &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover  {
                    background: rgba(255, 255, 255, 0.7); 
                }
            }
            .aug-customer-items__name{
                font-weight: $font-weight-semibold;
                font-family: $font-family-secondary;
                font-size: $font-size24;
                line-height: 34px;
                letter-spacing: -2px;
                min-height: 68px;
                @media (max-width: 767.98px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            .aug-customer-items__designation{
                font-weight: $font-weight-normal;
                font-size: $font-size16;
                line-height: 24px;
                opacity: 70%;
                min-height: 48px;
                margin-bottom: 32px;
                @media (max-width: 767.98px) {
                    margin-bottom: 40px;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
            .aug-customer-items__quote{
                text-align: right;
                @media (max-width: 767.98px) {
                    svg{
                        height: 45px;
                        width: 60px;
                    }
                }
            }
        }
    }
}

[dir="rtl"]{
    &.aug-customer-say{
        .slick-dots{
            text-align: right;
        }
        .slick-prev {
            right: initial;
            left: 0;
        }
        .slick-next {
            left: 54px;
            right: initial;
        }
        .aug-customer-slider{
            .aug-customer-items{
                .aug-customer-items__personal{
                    padding-left: 130px;
                    padding-right: initial;
                }
                img{
                    left: 0;
                    right: initial;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1); 
                }
                .aug-customer-items__quote{
                    text-align: left;
                }
            }
        }
    }
}
