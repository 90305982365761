//import css
@import '../../../Assets/scss/style.scss';

.aug-our-story{
    background-color: $secondary-color;
    border-radius: 40px;
    padding: 80px 0;
    @media (max-width: 991.98px) {
        padding: 60px 0;
    }
    @media (max-width: 767.98px) {
        padding: 40px 0;
        p{
            font-size: 14px;
            line-height: 21px;
        }
    }
    .aug-our-story__title{
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        font-weight: 700;
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 24px;
        }
    }
}