//import css
@import '../../../Assets/scss/style.scss';

.aug-empowering-clinicians{
    padding: 140px 0;
    @media (max-width: 767.98px) {
        padding: 60px 0;
    }
    .aug-career__title{
        @media (max-width: 767.98px) {
            br{
                display: none;
            }
        }
    }
    .aug-empowering-clinicians__tab{
        .gx-80 {
            --bs-gutter-x: 60px;
        }
        @media (max-width: 1199.98px) {
            .gx-80 {
                --bs-gutter-x: 40px;
            }
        }
        @media (max-width: 1024.98px) {
            .gx-80 {
                --bs-gutter-x: 30px;
            }
        }
        .nav-pills{
            .nav-link{
                cursor: default;
                padding: 0;
                &.active{
                    background-color: $secondary-color;
                    padding: 8px 18px 26px 0;
                    border-radius: 20px;
                    cursor: default;
                    margin-top: 18px;
                    .aug-empowering-clinicians__accordion-box__text{
                        display: block;
                    }
                    .aug-empowering-clinicians__accordion-box__title{
                        border-bottom: 0;
                        padding-bottom: 0;
                        cursor: default;
                    }
                    @media (max-width: 767.98px) {
                        padding: 0px 12px 24px;
                    }
                }
            }
        }
        .aug-empowering-clinicians__accordion-box__title{
            font-size: $font-size24;
            line-height: 34px;
            font-family: $font-family-secondary;
            font-weight: $font-weight-semibold;
            color: $white-color;
            cursor: pointer;
            display: flex;
            align-items: center;
            letter-spacing: -2px;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding: 18px 0 18px 18px;
            text-align: left;
            svg{
                margin-right: 8px;
            }
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
                svg{
                    margin-right: 6px;
                    height: 32px;
                    width: 32px;
                }
            }
        }
        .aug-empowering-clinicians__accordion-box__text{
            opacity: 70%;
            font-size: $font-size18;
            line-height: 27px;
            margin-top: 16px;
            margin-left: 74px;
            text-align: left;
            display: none;
            @media (max-width: 767.98px) {
                line-height: 21px;
                font-size: 14px;
                margin-top: 12px;
                margin-left: 52px;
            }
        }
        .aug-empowering-clinicians__accordion-img{
            img{
                border-radius: 20px;
                width: 100%;
            } 
        }
        .accordion-item{
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            color: $white-color;
            .aug-empowering-clinicians__accordion-box__title{
                margin-bottom: 0;
                @media (max-width: 767.98px) {
                    font-size: $font-size18;
                    line-height: 24px;
                    svg{
                        margin-right: 6px;
                        height: 32px;
                        width: 32px;
                    }
                }
            }
            .aug-empowering-clinicians__accordion-box__text{
                background-color: $secondary-color;
            }
        }
    }

    .accordion{
        .accordion-item{
            .accordion-item-content{
                    background-color: $secondary-color;
                    padding: 8px 12px 24px 0;
                    border-radius: 20px;
                    margin-top: 24px;
                .aug-empowering-clinicians__accordion-box__title{
                    border-bottom: 0;
                    padding: 16px 0 12px 12px;
                }
                .aug-empowering-clinicians__accordion-box__text{
                    display: block;
                    margin-top: 0;
                }
                &.collapsed{
                    background-color: transparent;
                    padding: 8px 12px 12px 0;
                    margin-top: 0;
                    .aug-empowering-clinicians__accordion-box__text{
                        display: none;
                    }
                }
            }
            .aug-empowering-clinicians__accordion-img{
                margin-bottom: 24px;
            }
        }
    }
}

[dir="rtl"]{
    &.aug-empowering-clinicians{
        .aug-empowering-clinicians__tab{
            .aug-empowering-clinicians__accordion-box__title{
                text-align: right;
                padding: 18px 18px 18px 0;
                svg {
                    margin-left: 8px;
                    margin-right: initial;
                }
            }
            .nav-pills{
                .nav-link{
                    &.active{
                        padding: 8px 0 26px 18px;
                    }
                }
            }
            .aug-empowering-clinicians__accordion-box__text{
                text-align: right;
                text-align: initial;
                margin-right: 74px;
                margin-left: initial;
                @media (max-width: 767.98px) {
                    margin-right: 52px;
                    margin-left: initial;
                }
            }
        }

        .accordion{
            .accordion-item{
                .accordion-item-content{
                        padding: 8px 0 24px 12px;
                    .aug-empowering-clinicians__accordion-box__title{
                        padding: 16px 12px 12px 0;
                    }
                    &.collapsed{
                        padding: 8px 0 12px 12px;
                    }
                }
            }
        }
    }
}
