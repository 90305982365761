//import css
@import '../../../App.scss';

.aug-secure-design{
    .aug-secure-design-wrap{
        padding: 80px 0 140px 0;
        @media (max-width: 767.98px) {
            padding: 60px 0 100px 0;
        }
    }
    h2{
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
        }
    }
    p{
        opacity: 70%;
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 991.98px) {
            margin-bottom: 40px;
        }
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .aug-secure-design__img{
        ul{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            @media (max-width: 991.98px) {
                margin-top: 40px;
            }
            @media (max-width: 767.98px) {
                margin-top: 32px;
            }
            li{
                background-color: $secondary-color;
                border-radius: 8px;
                margin-right: 16px;
                width: calc(33.33% - 16px);
                margin-top: 16px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 6px;
                @media (max-width: 991.98px) {
                    margin-right: 16px;
                }
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }
}
[dir="rtl"]{
    &.aug-secure-design {
        .aug-secure-design__img{
            ul{
                padding-right: 0;
                li{
                    margin-right: 26px;
                    margin-left: initial;
                    @media (max-width: 991.98px) {
                        margin-left: initial;
                        margin-right: 24px;
                    }
                    @media (max-width: 767.98px) {
                        margin-left: initial;
                        margin-right: 8px;
                    }
                    &:first-child{
                        margin-left: initial;
                        margin-right: 0px;
                    }
                }
            }
        }
    } 
    &.aug-secure-design-ar{
        .aug-secure-design-wrap{
            .aug-secure-design__img{
                ul{
                    li{
                        margin-left:16px !important;
                        margin-right: initial !important;
                        width: calc(33.33% - 16px);
                        &:last-child{
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}