$primary-color: #0C0C0C;
$secondary-color:#202020;
$white-color:#ffffff;
$border-color:#D3DBD2;
$active-color:#2CE100;
$danger-color:#FF6363;


$font-family: 'Stage Grotesk', sans-serif;
$font-family-secondary: 'Brooklyn-Heavy', sans-serif;

$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size24: 24px;
$font-size32: 32px;
$font-size48: 48px;
$font-size56: 56px;

$font-weight-light:300;
$font-weight-normal:400;
$font-weight-semibold:600;
$font-weight-bold:700;

$line-height-normal:24px;
$line-height-36:36px;
$line-height-40:40px;
$line-height-56:56px;
$line-height-64:64px;

$sm: 575.98px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl:1399.98px;

