//import css
@import '../../../Assets/scss/style.scss';

.aug-legal-banner{
    padding-top: 120px;
    @media (max-width: 767.98px) {
        padding-top: 60px;
    }
    h6{
        color: $active-color;
        font-weight: $font-weight-normal;
        font-size: $font-size18;
        line-height: 27px;
    }
    h1{
        font-family: $font-family-secondary;
        font-size: 44px;
        line-height: 58px;
        font-weight: $font-weight-bold;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: $font-size32;
            line-height: 38px;
        }
    }
    p{
        font-size: $font-size18;
        line-height: 27px;
        opacity: 70%;
        @media (max-width: 767.98px) {
            font-size: $font-size16;
            line-height: 24px;
        }
    }
}