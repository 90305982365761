//import css
@import '../../../App.scss';

.aug-leading-platforms{
    .aug-leading-platforms-wrap{
        padding: 80px 0;
        @media (max-width: 991.98px) {
            padding-bottom: 28px;
        }
        @media (max-width: 767.98px) {
            padding: 60px 0 28px;
        }
    }
    h2{
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            padding: 60px 0 30px;
            font-size: 26px;
            line-height: 34px;
        }
    }
    p{
        opacity: 70%;
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 991.98px) {
            margin-bottom: 40px;
        }
        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .btn{
        margin-top: 40px;
    }
    .aug-leading-platforms__img{
        @media (max-width: 991.98px) {
            margin-top: 60px;
        }
        @media (max-width: 767.98px) {
            margin-top: 40px;
        }
       
        .aug-leading-platforms-slider{
            .slick-track{
                display: flex;
                align-items: center;
            }
            .slick-slide{
                padding: 0 16px;
                ul{
                    li{
                        margin-bottom: 32px;
                        min-height: 45px;
                        display: flex;
                        img{
                            object-fit: contain;
                            max-height: 45px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
[dir="rtl"]{
    &.aug-secure-design {
        .aug-secure-design__img{
            ul{
                padding-right: 0;
                li{
                    margin-right: 26px;
                    margin-left: initial;
                    @media (max-width: 991.98px) {
                        margin-left: initial;
                        margin-right: 50px;
                        &:first-child{
                            margin-left: initial;
                            margin-right: 0px;
                        }
                    }
                    @media (max-width: 767.98px) {
                        margin-left: initial;
                        margin-right: 16px;
                        &:first-child{
                            margin-left: initial;
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    } 
}