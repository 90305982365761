//import css
@import '../../../Assets/scss/style.scss';

.aug-about-banner{
    margin: 120px 0 140px;
    @media (max-width: 991.98px){
        margin: 90px 0 120px;
    }
    @media (max-width: 767.98px){
        margin: 60px 0 100px;
    }
    .aug-about-banner-name{
        color: $active-color;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 767.98px){
            font-size: 16px;
            line-height: 24px;
        }
    }
    .aug-about-banner-title{
        font-size: 44px;
        line-height: 58px;
        letter-spacing: -2px;
        font-weight: 700;
        font-family: $font-family-secondary;
        margin-bottom: 16px;
        @media (max-width: 767.98px){
            font-size: 32px;
            line-height: 38px;
        }
    }
    .aug-about-banner-text{
        font-size: 18px;
        line-height: 27px;
        opacity: 70%;
        @media (max-width: 767.98px){
            font-size: 16px;
            line-height: 24px;
        }
    }
    .aug-about-banner-img{
        img{
            margin-top: 80px;
            width: 488px;
            height: 652px; 
            object-fit: cover;
        }
    }
    .aug-about-banner-list{
        @media (max-width: 991.98px){
            margin-top: 80px;
        }
        @media (max-width: 767.98px){
            margin-top: 60px;
        }
        li{
            padding-bottom: 40px;
            position: relative;
            margin-left: 28px;
            &::before{
                height: 12px;
                width: 12px;
                background-color: $active-color;
                border-radius: 50%;
                content: '';
                position: absolute;
                left: -28px;
                top: 12px;
            }
            &::after{
                width: 1px;
                background-color: #505050;
                height: calc(100% - 24px);
                content: '';
                position: absolute;
                left: -22px;
                top: 30px;
            }
            &:last-child{
                padding-bottom: 0;
                position: relative;
                &::before{
                    position: absolute;
                    top: 12px;
                    left: -32px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-radius: 0;
                    background-color: transparent;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 13px solid #2CE100;
                }
                &::after{
                    display: none;
                }
            }
            @media (max-width: 767.98px){
                padding-bottom: 32px;
            }
            h4{
                font-family: $font-family-secondary;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -2px;
                @media (max-width: 767.98px){
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            p{
                opacity: 70%;
                font-size: 16px;
                line-height: 24px;
                @media (max-width: 767.98px){
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
    } 
}

[dir="rtl"] {
    &.aug-about-banner{
        .aug-about-banner-img{
            img{
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
        .aug-about-banner-list{
            li{
                margin-right: 28px;
                margin-left: initial;
                &::before{
                    left: initial;
                    right: -28px;
                }
                &::after{
                    left: initial;
                    right: -22px;
                }
                &:last-child{
                    &::before{
                        left: initial;
                        right: -32px;
                    }
                }  
            }
        }
    }
}