//import css
@import '../../../Assets/scss/style.scss';

.aug-detecting-location{
    background-color: $primary-color;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    text-align: center;
    width: 100%;
    .aug-detecting-location-box{
        display: flex;
        justify-content: center;
        .aug-detecting-location-icon{
            width: 80px;
            height: 80px;
            @media (max-width: 767.98px) { 
                width: 60px;
                height: 60px;
            }
            background-color: $secondary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            svg{
                path{
                    fill: $white-color;
                }
            }
        }
    }
    p{
        font-size: $font-size20;
        span[class^="dot-"]{
            opacity: 0;
            font-size: $font-size32;
        }
        .dot-one{
            animation: dot-one 2s infinite linear
        }
        .dot-two{
            animation: dot-two 2s infinite linear
        }
        .dot-three{
            animation: dot-three 2s infinite linear
        }
        @keyframes dot-one{
            0%{
                opacity: 0;
            }
            15%{
                opacity: 0;
            }
            25%{
                opacity: 1;
            }
            100%{
                opacity: 1;
            }
        }
        @keyframes dot-two{
            0%{
                opacity: 0;
            }
            25%{
                opacity: 0;
            }
            50%{
                opacity: 1;
            }
            100%{
                opacity: 1;
            }
        }
        @keyframes dot-three{
            0%{
                opacity: 0;
            }
            50%{
                opacity: 0;
            }
            75%{
                opacity: 1;
            }
            100%{
                opacity: 1;
            }
        }
    }
    .aug-location-try{
        color: #006FDA;
        text-decoration-line: underline;
    }
    .aug-detecting-location-error-icon{
        path:first-child{
            fill: #ffffff !important;
        }
        path{
            fill: #F7BC1C !important;
        }
    }
}