//import css
@import '../../../Assets/scss/style.scss';

.aug-conversations-clinical{
    background-color: $secondary-color;
    padding: 100px 0 20px;
    border-radius: 40px;
    margin-bottom: 140px;
    @media (max-width: 991.98px) {
        padding: 80px 0px 0px;
    }
    @media (max-width: 767.98px) {
        padding: 60px 0 20px;
        margin-bottom: 100px;
    }
    .aug-conversations-clinical__title{
        font-family: $font-family-secondary;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 16px;
        letter-spacing: -2px;
        @media (max-width: 767.98px) {
            font-size: 26px;
            line-height: 34px;
        }
    }
    .aug-conversations-clinical__text{
        font-size: $font-size18;
        line-height: 27px;
        opacity: 70%;
        @media (max-width: 767.98px) {
            font-size: $font-size16;
            line-height: 24px;
        }
    }
    .aug-conversations-clinical__item{
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        @media (max-width: 767.98px) {
            margin-bottom: 40px;
        }
        .aug-conversations-clinical__item-number{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            font-family: $font-family-secondary;
            letter-spacing: -2px;
            margin-bottom: 8px;
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
            }
        }
        .aug-conversations-clinical__item-text{
            opacity: 70%;
            margin-bottom: 0;
        }
    }
}