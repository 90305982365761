//import css
@import '../../../App.scss';

.aug-banner-video{
    .aug-banner-video-slider{
        .slick-arrow{
            background-color: $secondary-color;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            z-index: 9;
            @media (max-width: 767.98px) {
                height: 24px;
                width: 24px;
            }
            &.slick-next{
                &::before{
                    display: none;
                }
                right: 0px;
                @media (max-width: 991.98px) {
                    right: 8px;
                }
                @media (max-width: 767.98px) {
                    right: 18px;
                }
            }
            &.slick-prev{
                &::before{
                    display: none;
                }
                left: 0px;
                @media (max-width: 991.98px) {
                    left: 8px;
                }
                @media (max-width: 767.98px) {
                    left: 18px;
                }
            }
        }
        .aug-banner-video-items{
            .aug-banner-video-items-title{
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -2px;
                font-family: $font-family-secondary;
                text-align: center;
                margin-bottom: 16px;
                @media (max-width: 767.98px) {
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 12px;
                }
            }
            img{
                background-color: $secondary-color;
                border-radius: 40px;
                @media (max-width: 991.98px) {
                    border-radius: 24px;
                }
            }
            // .aug-banner-video-items-name{
            //     font-size: 24px;
            //     line-height: 34px;
            //     text-align: center;
            //     font-family: $font-family-secondary;
            //     font-weight: $font-weight-semibold;
            //     margin-top: 16px;
            //     @media (max-width: 767.98px) {
            //         font-size: 18px;
            //         line-height: 24px;
            //     }
            // }
        }
        .slick-dots{
            bottom: -34px;
            li{
                button{
                    padding: 0;
                    &::before{
                        background-color: $secondary-color;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        opacity: 1;
                        content: "";
                    }
                }
                &.slick-active{
                    button{
                        padding: 0;
                        &::before{
                            background-color: $active-color;
                            height: 8px;
                            width: 24px;
                            border-radius: 100px;
                            content: "";
                        }
                    }
                }
            }
        }
        // .slick-prev, .slick-next{
        //     top: 45%;
        // }
    }
}

[dir="rtl"]{
    &.aug-banner-video{
        .aug-banner-video-slider{
            .slick-arrow{
                rotate: 180deg;
                &.slick-next{
                    right: initial;
                    left: 0px;
                    @media (max-width: 991.98px) {
                        left: 8px;
                    }
                }
                &.slick-prev{
                    left: initial;
                    right: 0px;
                    @media (max-width: 991.98px) {
                        right: 8px;
                    }
                }
            }            
        }
    } 
}