@charset 'utf-8';

//Base
@import "base/reset";

//Abstracts
@import "abstracts/fonts";
@import "abstracts/variables";

//Components
@import "components/button";
@import "components/modal";

//Pages
@import "pages/home";

//Generic Class
.text-secondary{
    color: $secondary-color!important;
}
.text-primary{
    color: $primary-color!important;
}
.text-active{
    color: $active-color!important;
}
.border-top{
    border-color: rgba(211, 219, 210, 0.5) !important;
}

.gx-40 {
    --bs-gutter-x: 40px;
}
.gx-80 {
    --bs-gutter-x: 80px;
}
.gx-6 {
    --bs-gutter-x: 6px;
}
.gx-16 {
    --bs-gutter-x: 16px;
}
.g-8{
    --bs-gutter-x: 8px;
    --bs-gutter-y: 8px;
}
.list-disc{
    list-style-type: disc;
    @media (max-width: 767.98px) {
        padding-left: 20px;
    }
}

//Globle Class
.aug-title{
    color: $secondary-color;
    font-size: $font-size32;
    font-weight: $font-weight-normal;
    line-height: $line-height-36;
    @media (max-width: 991.98px) {
        font-size: 32px;
    }
    @media (max-width: 767.98px) {
        font-size: 24px;
    }
}

.aug-page-margin{
    margin-top: 144px;
    @media (max-width: 767.98px) {
        margin-top: 74px;
    }
}

.img-fluid{
    aspect-ratio: auto;
}

.aug-spectra{
    video{
        border: 2px solid $active-color;
        border-radius: 26px;
    }
}