//import css
@import '../../../Assets/scss/style.scss';

.aug-we-believe{
    padding-top: 140px;
    @media (max-width: 767.98px) {
        padding-top: 100px;
    }
    .aug-we-believe__item{
        border: 1px solid rgba(80, 80, 80, 0.5);
        background-color: $secondary-color;
        border-radius: 20px;
        padding: 32px 24px;
        height: calc(100% - 40px);
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 24px;
        }
        h3{
            font-weight: $font-weight-semibold;
            font-size: $font-size24;
            line-height: 34px;
            font-family: $font-family-secondary;
            letter-spacing: -2px;
            @media (max-width: 767.98px) {
                font-size: $font-size18;
                line-height: 24px;
            }
        }
        p{
            opacity: 70%;
            margin-top: 16px;
            @media (max-width: 767.98px) {
                font-size: $font-size14;
                line-height: 21px;
                margin-top: 12px;
            }
        }
        .aug-we-believe__icone{
            position: relative;
            margin-bottom: 40px;
            @media (max-width: 767.98px) {
                margin-bottom: 24px;
            }
            &::after{
                background-color: #2c6b2f;
                filter: blur(25px);
                content: "";
                border-radius: 100px;
                height: 32px;
                width: 32px;
                top: 0;
                left: 8px;
                position: absolute;
            }
        }
    }
}
[dir="rtl"]{
    &.aug-we-believe{
        .aug-we-believe__icone{
            &::after{
                right: 8px;
                left: initial;
            }
        }
    }
}