.aug-omni-banner{
    &.aug-spectra-banner{
        .aug-spectra-video{
            @media (min-width: 992px) {
                margin-top: 0;
            }
            @media (max-width: 991.98px) {
                margin-top:80px
            }
            @media (max-width: 767.98px) {
                margin-top:60px
            }
        }
    }
}